.footer-container{
    color: #324d67;
    text-align: center;
    margin-top: 20px;
    padding: 30px 10px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
  
  }
  .footer-container .icons{
    font-size: 30px;
    display: flex;
    gap: 10px;
  }

  .footer-container .icons a {
    color:#000 !important
  }